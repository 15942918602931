import revive_payload_client_4sVQNw7RlN from "/opt/apps/transkribus_app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/apps/transkribus_app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/apps/transkribus_app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/apps/transkribus_app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/apps/transkribus_app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/apps/transkribus_app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/apps/transkribus_app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/apps/transkribus_app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/opt/apps/transkribus_app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_EG2xeLuEip from "/opt/apps/transkribus_app/node_modules/vue3-swatches/nuxt/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/apps/transkribus_app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import api_GFfDXud5Cr from "/opt/apps/transkribus_app/plugins/api.ts";
import chartjs_1wC9lAM0Tp from "/opt/apps/transkribus_app/plugins/chartjs.ts";
import error_handler_kEP5FliEXj from "/opt/apps/transkribus_app/plugins/error-handler.ts";
import event_bus_S6Iq1iGe5d from "/opt/apps/transkribus_app/plugins/event-bus.ts";
import features_psYqsnyAbD from "/opt/apps/transkribus_app/plugins/features.ts";
import hubspot_faxzxP5z7u from "/opt/apps/transkribus_app/plugins/hubspot.ts";
import notifications_Wskzy5ZO71 from "/opt/apps/transkribus_app/plugins/notifications.ts";
import osDetector_hxWPBenTgF from "/opt/apps/transkribus_app/plugins/osDetector.ts";
import posthog_ulvgx15Dgc from "/opt/apps/transkribus_app/plugins/posthog.ts";
import rs_locales_KrbbMXxgJl from "/opt/apps/transkribus_app/plugins/rs-locales.ts";
import sentry_client_shVUlIjFLk from "/opt/apps/transkribus_app/plugins/sentry.client.ts";
import splitpanes_8MtPXNLwbO from "/opt/apps/transkribus_app/plugins/splitpanes.ts";
import svg_Uew0JGO3gG from "/opt/apps/transkribus_app/plugins/svg.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  plugin_client_EG2xeLuEip,
  chunk_reload_client_UciE0i6zes,
  api_GFfDXud5Cr,
  chartjs_1wC9lAM0Tp,
  error_handler_kEP5FliEXj,
  event_bus_S6Iq1iGe5d,
  features_psYqsnyAbD,
  hubspot_faxzxP5z7u,
  notifications_Wskzy5ZO71,
  osDetector_hxWPBenTgF,
  posthog_ulvgx15Dgc,
  rs_locales_KrbbMXxgJl,
  sentry_client_shVUlIjFLk,
  splitpanes_8MtPXNLwbO,
  svg_Uew0JGO3gG
]