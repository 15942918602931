export default defineNuxtPlugin(nuxtApp => {
  // TODO check if we can use the feature flag in posthog
  const config = useRuntimeConfig()

  const isAlpha = config.public.name === 'alpha'
  const isBeta = config.public.name === 'beta'
  const isApp = config.public.name === 'app'

  const features = {
    tableAdvancedSetting: isAlpha,
    scrollable: isAlpha,
    learn: isAlpha || isBeta,
    qc: isAlpha,
    trainingRuns: isAlpha,
    comments: isAlpha,
    pageMetadata: isAlpha,
    uploadDemo: isAlpha || isBeta,
    invitations: isAlpha,
    posthogTesting: isAlpha,
    simplifiedRecognition: isAlpha || isBeta,
    showDocStatus: isAlpha,
    newUI: isAlpha || isBeta,
    refreshTokenForSave: isAlpha || isBeta,
    dan: isAlpha || isBeta,
    dataLabTrain: isAlpha || isBeta,
    s3ModelThumbnail: isAlpha || isBeta,
    documentLabels: isAlpha || isBeta,
    orchestratorTest: isAlpha,
    copyPublicLink: isAlpha || isBeta,
    conditionalBanner: isAlpha || isBeta || isApp,
    notifications: isAlpha,
    creditsUserSelection: isAlpha,
  }
  nuxtApp.provide('features', features)
})
